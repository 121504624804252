@import "animations";
@font-face {
	font-family: 'Rubik';
	src: local('Rubik'),
		    url('../fonts/Rubik-VariableFont_wght.ttf') format('truetype');
	font-weight: 300 900;
	font-style: normal;
}

html {
    scroll-behavior: smooth; 

    ::-webkit-scrollbar{
        width: 7px;
        height: 7px;

    }
    ::-webkit-scrollbar-thumb{
        background: #07114a;
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover{
        background: #02082c;
    }
    ::-webkit-scrollbar-track{
        background: #ff000000;
        border-radius: 0px;
        // box-shadow: inset 1px 1px 3px 2px #c1c1c1;
    }

    body{
        margin: 0;
        overflow-x: hidden;
        // font-family: "Rubik";
        position: relative;
        direction: rtl;

        *, *::after, *::before{
            transition: all ease-out 200ms;
            box-sizing: border-box;
        }

        a{
            text-decoration: none;
            color: inherit;
        }

        img{
            max-width: 100%;
            max-height: 100%;
        }

        //position
        .relative{
            position: relative;
        }
        
        .absolute{
            position: absolute;
        }

        .fixed{
            position: fixed;
        }

        //flex
        .flex{
            display: flex;
        }

        .column{
            flex-direction: column;
        }

        .align-center{
            align-items: center;
        }

        .align-start{
            align-items: flex-start;
        }

        .align-end{
            align-items: flex-end;
        }

        .align-base{
            align-items: baseline;
        }

        .justify-center{
            justify-content: center;
        }

        .justify-start{
            justify-content: flex-start;
        }

        .justify-end{
            justify-content: flex-end;
        }

        .justify-between{
            justify-content: space-between;
        }

        .wrap{
            flex-wrap: wrap;
        }

        .nowrap{
            flex-wrap: nowrap;
        }

        .row{
            flex-direction: row;
        }

        .row-reverse{
            flex-direction: row-reverse;
        }

        .theguy_logo{
            position: absolute;
            left: 50%;
            bottom: 2%;
            transform: translateX(-50%);

            svg{
                path{
                    fill: #fff;
                }
            }

            &:hover{
                svg{
                    path{
                        fill: #000;
                    }
                }
            }
        }

        .muirtl-1w8hhka-MuiTableCell-root span{
            color: #fff !important;
        }

        .muirtl-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon{
            fill: #fff;
            opacity: 1;
            path{
                fill: #fff;
            }
        }

        .MuiTextField-root.invalid{
            fieldset{
                background-color: rgba(255, 0, 0, 0.3) !important;
                border-color: red;
            }
        }

        .MuiTablePagination-toolbar{

            *{
                color: #fcfcfc;
            }

            p{
                font-weight: 700 !important;
            }

            button.Mui-disabled{
                opacity: 0.5;
            }
        }
    }
}
